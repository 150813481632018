<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="getPdfFileName(site_name, task_type == 'Service Request' ? 'Service_Request' : 'Defect', start, end)"
            :pdf-quality="3"
            :manual-pagination="true"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="landscape"
            pdf-content-width="1065px"
            @progress="onProgress($event)"
            @beforeDownload="beforeDownload($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="bg-white">

                <section class="pdf-item">
                    <b-row v-if="organization_logo || site_logo">
                        <b-col cols="6">
                            <img v-if="site_logo" :src="site_logo" alt="Logo" width="110" height="110" loading="eager"/>
                        </b-col>
                        <b-col cols="6">
                            <img v-if="organization_logo" :src="organization_logo" alt="Logo" width="110" height="110" class="float-right" loading="eager"/>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" class="text-center" v-if="task_type == 'Service Request'"><strong><span style="color: #000 !important; font-size: 22px;">Service Request Analytics</span></strong></b-col>
                        <b-col cols="12" class="text-center" v-else><strong><span style="color: #000 !important; font-size: 22px;">Defect Analytics</span></strong></b-col>
                    </b-row>
                    <b-row style="color: #000 !important; font-size: 12px !important;" class="pb-2 pt-1">
                        <b-col cols="3" class="my-font"><strong>Project Site</strong></b-col>
                        <b-col cols="3" class="my-font">: {{ site_name }}</b-col>                    
                        <b-col cols="3" class="my-font"><strong>Location</strong></b-col>
                        <b-col cols="3" class="my-font">: {{location_name ? location_name.toString() : 'All'}}</b-col>
                        
                        <b-col cols="3" class="my-font" v-if="task_type == 'Service Request'"><strong>Type</strong></b-col>
                        <b-col cols="3" class="my-font" v-if="task_type == 'Service Request'">: {{frequency ? frequency : 'All'}}</b-col>
                        <b-col cols="3" class="my-font" v-if="task_type == 'Service Request'"><strong>Crew</strong></b-col>
                        <b-col cols="3" class="my-font" v-if="task_type == 'Service Request'">: {{crew_name ? crew_name : 'All' | capitalize}}</b-col>
                        
                        <b-col cols="3" class="my-font"><strong>Start Date</strong></b-col>
                        <b-col cols="3" class="my-font">: {{sitedateTime(start,date_format,'' )}}</b-col>
                        <b-col cols="3" class="my-font"><strong>End Date</strong></b-col>
                        <b-col cols="3" class="my-font">: {{sitedateTime(end,date_format,'' )}}</b-col>

                        <b-col cols="3" class="my-font"><strong>Status</strong></b-col>
                        <b-col cols="3" class="my-font">: {{status ? status : 'All' | capitalize}}</b-col>

                 <!-- <b-col cols="3" class="my-font"><strong>Generated By</strong></b-col>
                        <b-col cols="3" class="my-font">: {{this.$store.getters.currentUser.full_name}}</b-col> -->
                    </b-row>
                    <!-- <b-row class="mt-3" v-show="data.length > 0 && data[2].trigger_count > 0"> -->
                    <b-row class="mt-3">

                        <b-col cols="8" class="pb-2">
                            <b-card-body class="border-secondary rounded" style="height: 98% !important">
                                <div class="doughnut">
                                    <h4 class="text-center mb-2 dark-text"><b>Statistics</b></h4>
                                    <b-row>
                                        <b-col cols="6">
                                            <vue-apex-charts
                                                type="pie"
                                                height="300"
                                                class="mt-0 mb-1"
                                                :options="chartOptions"
                                                :series="series"
                                                ref="pie_chartH1"
                                                v-if="series.reduce((val, el) => val + el, 0) > 0"
                                            />
                                            <div class="row align-items-center text-light" style="height: 250px" v-else> 
                                                <div class="col-md-12">
                                                    <h5 class="text-center dark-text">No Data Available</h5>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="6">
                                            <div class="pt-25 dark-text mt-5 pt-2">
                                                <div v-for="(data,index) in chartData.listData" 
                                                    :key="index" class="d-flex justify-content-between"
                                                    :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                                                >
                                                    <div class="series-info">
                                                        <feather-icon :icon="data.icon" size="16" class="mr-50" :class="data.iconColor"/>
                                                        <span class="font-weight-bolder">{{ data.text }}</span>
                                                    </div>
                                                    <span>{{ data.result }}</span>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>

                                </div>
                            </b-card-body>
                        </b-col>

                        <b-col cols="4" class="pb-2">

                            <b-card-body class="dark-text border-secondary rounded" style="height: 98% !important">
                                <h4 class="text-center mb-2 dark-text"><b>Summary</b></h4>
                                <b-row class="mt-2">
                                    <b-col md="12">
                                        <div class="pt-25 dark-text">
                                            <div 
                                                v-for="(data,index) in chartData.summaryData"
                                                :key="index" class="d-flex justify-content-between"
                                                :class="index === Object.keys(chartData.summaryData).length - 1 ? '':'mb-1'"
                                            >
                                                <div class="series-info">
                                                <feather-icon :icon="data.icon" size="16" class="mr-50" :class="data.iconColor"/>
                                                <span class="font-weight-bolder">{{ data.text }}</span>
                                                </div>
                                                <span>{{ data.result }}</span>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>

                            </b-card-body>

                        </b-col>

                    </b-row>
                </section>

                <!-- <div class="html2pdf__page-break"/> -->

                <!-- <section class="pdf-item" v-if="task_type == 'Service Request'">
                    <table class="table text-center" style="page-break-inside: avoid !important;">
                        <tr>
                            <th width="16%" scope="col">ID</th>
                            <th width="22%" scope="col">Location</th>
                            <th width="20%" scope="col">Task</th>
                            <th width="13%" scope="col">Crew</th>
                            <th width="11%" scope="col">Start Date</th>
                            <th width="11%" scope="col">End Date</th>
                            <th width="7%" scope="col">Status</th>
                        </tr>
                    </table>
                </section> -->
                
                <section class="pdf-item" v-if="task_type == 'Service Request'" v-for="(item, ind) in this.items" :key="ind">
                    <table class="table text-center mt-1" style="page-break-inside: avoid !important;">
                        <tr v-if="ind == 0" class="default-height">
                            <th width="16%" scope="col">ID</th>
                            <th width="21%" scope="col">Location</th>
                            <th width="21%" scope="col">Task</th>
                            <th width="13%" scope="col">Crew</th>
                            <th width="11%" scope="col">Start Date</th>
                            <th width="11%" scope="col">End Date</th>
                            <th width="7%" scope="col">Status</th>
                        </tr>
                        <tr style="page-break-inside: avoid !important;" :class="ind % 2 == 0 ? 'white-bg' : 'grey-bg'" class="default-height">
                            <td width="16%" align="left" style="padding-left: 4px !important;">{{ item.unique_id }}</td>
                            <td width="21%" align="left" style="padding-left: 4px !important;">
                                <span class="wordBreak" v-for="(location, ind) in item.locations" :key="ind"> {{location.name | capitalize}}</span>
                            </td>
                            <!-- <td width="12%">{{ item.site.site_name }}</td> -->
                            <td width="21%" align="left" style="padding-left: 4px !important;">{{ item.title }}</td>
                            <td width="13%">{{ item.crew.length > 0 ? item.crew[0].full_name : 'N/A' | capitalize }}</td>
                            <td width="11%">
                                <span class="wordBreak">{{ sitedateTime(item.start, date_format, '' )}}</span>
                            </td>
                            <td width="11%">
                                <span class="wordBreak">{{ sitedateTime(item.end, date_format, '' )}}</span>
                            </td>
                            <td width="7%">{{ item.task_status | capitalize}}</td>
                        </tr>
                    </table>
                </section>

                <!-- <section class="pdf-item" v-if="task_type == 'Defects'">
                    <table class="table text-center" style="page-break-inside: avoid !important;">
                        <tr>
                            <th width="18%" scope="col">ID</th>
                            <th width="25%" scope="col">Location</th>
                            <th width="20%" scope="col">Defect</th>
                            <th width="14%" scope="col">Start Date</th>
                            <th width="14%" scope="col">End Date</th>
                            <th width="9%" scope="col">Status</th>
                        </tr>
                    </table>
                </section> -->
                
                <section class="pdf-item" v-if="task_type == 'Defects'" v-for="(item, ind) in this.items" :key="ind">
                    <table class="table text-center mt-1" style="page-break-inside: avoid !important;">
                        <tr v-if="ind == 0" class="default-height">
                            <th width="19%" scope="col">ID</th>
                            <th width="22%" scope="col">Location</th>
                            <th width="22%" scope="col">Defect</th>
                            <th width="14%" scope="col">Start Date</th>
                            <th width="14%" scope="col">End Date</th>
                            <th width="9%" scope="col">Status</th>
                        </tr>
                        <tr style="page-break-inside: avoid !important;" :class="ind % 2 == 0 ? 'white-bg' : 'grey-bg'" class="default-height">
                            <td width="19%" align="left" style="padding-left: 4px !important;">{{ item.unique_id }}</td>
                            <td width="22%" align="left" style="padding-left: 4px !important;">
                                <span class="wordBreak" v-for="(location, ind) in item.locations" :key="ind"> {{location.name | capitalize}}</span>
                            </td>
                            <!-- <td width="12%">{{ item.site.site_name }}</td> -->
                            <td width="22%" align="left" style="padding-left: 4px !important;">{{ item.title }}</td>
                            <td width="14%">
                                <span class="wordBreak">{{ sitedateTime(item.start, date_format, '' )}}</span>
                            </td>
                            <td width="14%">
                                <span class="wordBreak">{{ sitedateTime(item.end, date_format, '' )}}</span>
                            </td>
                            <td width="9%">{{ item.task_status | capitalize}}</td>
                        </tr>
                    </table>
                </section>

            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
    import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup, BBadge, BDropdown, BDropdownItem, 
        BPagination, BFormCheckbox, BCardBody, BFormSelectOption, BFormSelect, BFormTextarea, BForm, BFormRadio, 
        BBreadcrumb, BFormRadioGroup, BTabs, BTab
	} from 'bootstrap-vue';
    import { POST_API } from "../../../store/actions.type";
    import moment from "moment-timezone";
	import VueApexCharts from 'vue-apexcharts';
    import VueHtml2pdf from 'vue-html2pdf';
	import Bus from "../../../event-bus";
    import { $themeColors } from '@themeConfig'

    export default {
        name: 'Export',
		components: {
			BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
            BPagination, BFormCheckbox, BFormSelect, BFormSelectOption, VueApexCharts, BFormTextarea, BAlert, BFormGroup, BForm, 
			BFormRadio, BBreadcrumb, VueHtml2pdf, BFormRadioGroup, BTabs, BTab
		},

		data() {
			return {
				organization_logo : '',
                site_logo : '',
                main_logo : '',

                site          : null,
                location      : [], 
                frequency     : null,
                group         : [],
                crew          : '',
                status        : '',
                start         : '',
                end           : '',
                site_name     : '',
                location_name : [],
                crew_name     : '',
                date_format   : '',
                task_type     : 'Service Request',

                chartData: {
                    listData:[],
                    summaryData:[],
                },

                items: [],

                // pie chart 

                series:[],
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    labels: ['Open', 'In Progress', 'Closed','Validate','Failed'],
                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: false,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000'],
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    colors: [$themeColors.primary, $themeColors.warning, $themeColors.success,$themeColors.info,$themeColors.danger],
                },
			}
		},
		methods : {			
			// Export PDF functions
            exportPDF() {
                 Swal.fire({
                    title: 'Please Wait',
                    html: 'PDF is Generating.',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                }); 

                //Bus.$emit('showLoader');
                this.getHeaderImages().then(() => {
                    this.getData().then(() => {
                        var time = 10000;
                        setTimeout(()=>{
                            this.$refs.html2Pdf.generatePdf();
                        }, time);
                    })
                })

            },

            onProgress(event) {
                /* if(event == 100){
                    // Swal.fire({
                    //     position : 'center',
                    //     icon     : 'success',
                    //     title    : 'PDF Downloaded.',
                    //     timer    : 1500,
                    //     showConfirmButton : false
                    // });
                    Bus.$emit('hideLoader');
                    window.close()
                } */
            },

            async beforeDownload ({ html2pdf, options, pdfContent }) {
                options.margin = [0.5, 0.3, 0.6, 0.3]; // values for [top, left, bottom, right]
                options.useCORS = true;
                
                await html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
                        pdf.text('Report generated by ELTaskforce.', (pdf.internal.pageSize.getWidth() * 0.05), (pdf.internal.pageSize.getHeight() - 0.2))
                        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.2))
                    }
                }).save();

                setTimeout(()=>{
                    window.close()
                }, 2000);
            },

            /* async beforeDownload ({ html2pdf, options, pdfContent }) {
                // options.margin = .3;
                options.margin = [0.5, 0.3, 0.6, 0.3]; // values for [top, left, bottom, right]
                options.useCORS = true;
                // options.html2canvas = { scale: 2,useCORS: true };
                // options.enableLinks = true;

                // options.image = { type: "jpeg", quality: 0.98 };
                // options.html2canvas = { letterRendering: true };
                // options.jsPDF = { unit: "in", format: "a4", orientation: "landscape" };

                // options.image = { type: 'jpeg', quality: 1 };

                // await html2pdf().set(options);
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
                        pdf.text('Report generated by ELTaskforce.', (pdf.internal.pageSize.getWidth() * 0.05), (pdf.internal.pageSize.getHeight() - 0.2))
                        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.2))
                    }
                    // console.log(pdf);
                }).save();

                // console.log(options)

                // await html2pdf().set(options)

                // html2pdf().set(options).from(this.$refs.html2Pdf).save();
                
                // html2pdf().set(options).from(this.$refs.html2Pdf).toPdf().get('pdf').then((pdf) => {
                //     const totalPages = pdf.internal.getNumberOfPages()
                //     for (let i = 1; i <= totalPages; i++) {
                //         pdf.setPage(i)
                //         pdf.setFontSize(10)
                //         pdf.setTextColor(150)
                //         pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                //     }
                // }).save();
            }, */

            getHeaderImages(){
                if(this.site != 'all-site'){
                    return this.$store.dispatch(POST_API, {
                        data:{
                            site : this.site ? this.site : null
                        },
                        api: '/api/feedback-panel-logo'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } 
                        else {
                            this.organization_logo = this.$store.getters.getResults.data.organization_logo;
                            this.site_logo = this.$store.getters.getResults.data.site_logo;
                            this.main_logo = this.$store.getters.getResults.data.main_logo;
                        }
                    });
                }
            },

            getData(){
                var api = this.task_type == 'Service Request' ? '/api/task-analytics-pdf' : '/api/defect-analytics-pdf';
                return this.$store.dispatch(POST_API, {
                    data:{
                        site      : this.site,
                        frequency : this.frequency,
                        locations : this.location,
                        crew      : this.crew,
                        start     : this.start,
                        end       : this.end,
                        status    : this.status,
                        role      : this.$store.getters.currentUser.role,
                        om_sites  : this.$store.getters.currentUser.om_sites,
                        report    : 'service',
                    },
                    api: api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.series =  this.$store.getters.getResults.data.series;
                        this.chartData.listData = this.$store.getters.getResults.data.listData;
                        this.chartData.summaryData = this.$store.getters.getResults.data.summaryData;

                        // table vars
                        this.items        = this.$store.getters.getResults.data.docs;
                    }
                });
            }
		},

		mounted(){
            this.site          = this.$route.params.site;
            this.location      = this.$route.params.location != 'null' && this.$route.params.location.length > 0 ? this.$route.params.location.split(",") : null;
            this.task_type     = this.$route.params.task_type;
            this.frequency     = this.$route.params.frequency != 'null' ? this.$route.params.frequency : null;
            this.crew          = this.$route.params.crew != 'null' ? this.$route.params.crew : '';
            this.status        = this.$route.params.status != 'null' ? this.$route.params.status : '';
            this.start         = this.$route.params.start;
            this.end           = this.$route.params.end;
            this.site_name     = this.$route.params.site_name;
            this.location_name = this.$route.params.location_name != 'null' && this.$route.params.location_name.length > 0 ? this.$route.params.location_name.split(",") : null;
            this.crew_name     = this.$route.params.crew_name != 'null' ? this.$route.params.crew_name : null;
            this.date_format   = this.$route.params.date_format;

            this.exportPDF();
		}
	
	}
</script>
<style scoped>
.dark-text{
    color: black !important;
}
.dark-layout .apexcharts-canvas .apexcharts-yaxis-label, .dark-layout .apexcharts-canvas .apexcharts-xaxis-label, .dark-layout .apexcharts-canvas .apexcharts-tooltip-text, .dark-layout .apexcharts-canvas .apexcharts-datalabel-label {
    fill: black!important;
}
apexcharts-legend-text {
    color: black !important;
}

/* table css */
    table{
        padding: 0px !important;   
        margin: 0px !important;   
    }
    th {
        background-color: #D4AF37 !important; 
    }
    td, th {
        border-left: 1px solid #000000 !important;
        border-right: 1px solid #000000 !important;
        border-bottom: 1px solid #000000 !important;
        padding: 2px !important;
        color: #000 !important; 
    }
    td {
        border-top: 0px !important;
        font-size: 10px;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
    th {
        border-top: 1px solid #000000 !important;
        font-size: 10px;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    .white-bg{
        background-color: #ffffff !important;
    }
    .grey-bg{
        background-color: #ededed !important;
    }
    .default-height{
        height: 32px !important;
    }
</style>